<template>
  <el-dialog
    title="修改密码"
    :visible.sync="visible"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="isClose"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="80px"
    >
      <el-form-item label="账号">
        <span>{{ userName }}</span>
      </el-form-item>
      <el-form-item label="原密码" prop="password">
        <el-input type="password" v-model="dataForm.password"></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="newPassword">
        <!-- placeholder="长度为8~16个字符,小写字母/数字/标点符号/大写字母至少包含3种,以字母开头,以字母或数字结尾,不允许有连续数字" -->
        <el-input
          type="password"
          v-model="dataForm.newPassword"
          show-password
        ></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="confirmPassword">
        <el-input
          type="password"
          v-model="dataForm.confirmPassword"
          show-password
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button v-if="isClose" @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { clearLoginInfo } from "@/utils";
import { checkStrong } from "../../assets/pwStrength";
export default {
  props: {
    isClose: Boolean,
    default: true,
  },
  data() {
    var validateConfirmPassword = (rule, value, callback) => {
      if (this.dataForm.newPassword !== value) {
        callback(new Error("确认密码与新密码不一致"));
      } else {
        callback();
      }
    };
    return {
      visible: false,
      dataForm: {
        password: "",
        newPassword: "",
        confirmPassword: "",
      },
      dataRule: {
        password: [
          { required: true, message: "原密码不能为空", trigger: "blur" },
        ],
        newPassword: [
          { required: true, trigger: "blur", message: "请填写新密码" },
          {
            // message: "密码必须包含字母、数字和特殊字符，长度8-16，以字母开头，以字母或数字结尾",
            validator: passwordValidator,
          },
        ],
        confirmPassword: [
          { required: true, message: "确认密码不能为空", trigger: "blur" },
          { validator: validateConfirmPassword, trigger: "blur" },
        ],
      },
      newpassState: [
        {
          text: "长度为8~16个字符",
          state: false,
          hander(val) {
            const r = val.length > 7 && val.length < 17;
            this.state = r;
            return r;
          },
        },
        {
          text: "小写字母/数字/标点符号/大写字母至少包含3种",
          state: false,
          hander(val) {
            const r = checkStrong(val) > 2;
            this.state = r;
            return r;
          },
        },
        {
          text: "以字母开头，以字母或数字结尾",
          state: false,
          hander(val) {
            const r = /^[a-z]/i.test(val) && /[a-z0-9]$/i.test(val);
            this.state = r;
            return r;
          },
        },
        {
          text: "不允许有空格、中文",
          state: false,
          hander(val) {
            const r = !/[\u4e00-\u9fa5]/.test(val) && !/\s/.test(val);
            this.state = r;
            return r;
          },
        },
        {
          text: "不允许有连续数字",
          state: false,
          hander(val) {
            const r = !/((?:0(?=1)|1(?=2)|2(?=3)|3(?=4)|4(?=5)|5(?=6)|6(?=7)|7(?=8)|8(?=9)|9(?=0)){2,3}\d)/.test(
              val
            );
            this.state = r;
            return r;
          },
        },
        {
          text: "不允许有连续数字",
          state: false,
          hander(val) {
            const r = !/((?:0(?=9)|9(?=8)|8(?=7)|7(?=6)|6(?=5)|5(?=4)|4(?=3)|3(?=2)|2(?=1)|1(?=0)){2,3}\d)/.test(
              val
            );
            this.state = r;
            return r;
          },
        },
      ],
    };
  },
  computed: {
    userName: {
      get() {
        return this.$store.state.user.name;
      },
    },
    mainTabs: {
      get() {
        return this.$store.state.common.mainTabs;
      },
      set(val) {
        this.$store.commit("common/updateMainTabs", val);
      },
    },
  },
  methods: {
    newpassValidator(rule, value, callback) {
      const r = this.newpassState
        .map((el) => el.hander(value))
        .reduce((acc, el) => acc && el, true);
      console.log(r);
      callback(r ? undefined : new Error());
    },
    // 初始化
    init() {
      this.visible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          this.$http({
            url: this.$http.adornUrl("/sys/user/password"),
            method: "post",
            data: {
              password: this.dataForm.password,
              newPassword: this.dataForm.newPassword,
            },
          })
            .then(({ data }) => {
              if (data && data.code === 200) {
                this.$message({
                  message: "操作成功",
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.visible = false;
                    this.$nextTick(() => {
                      this.mainTabs = [];
                      clearLoginInfo();
                    });
                  },
                });
              } else {
                this.$message.error(data.msg);
              }
            })
            .catch((err) => {
              this.$message.error(err.msg);
            });
        }
      });
    },
  },
};
const passwordValidator = (rule, val, callback) => {
  // if (!(val.length > 7 && val.length < 17)) {
  //   callback(new Error("长度为8~16个字符"));
  // }
  // if (!(checkStrong(val) > 2)) {
  //   callback(new Error("小写字母/数字/标点符号/大写字母至少包含3种"));
  // }
  // if (!(/^[a-z]/i.test(val) && /[a-z0-9]$/i.test(val))) {
  //   callback(new Error("以字母开头，以字母或数字结尾"));
  // }
  // if (!(!/[\u4e00-\u9fa5]/.test(val) && !/\s/.test(val))) {
  //   callback(new Error("不允许有空格、中文"));
  // }
    if (!/^([a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*_.,])[a-zA-Z\d!@#$%^&*_.,]{7,15}$/.test(val)) {
      callback(new Error("注：长度为8~16位字符，以字母开头，必须包括字母、数字、特殊字符3种。(特殊字符：!@#$%^&*_.,)"));
    } else {
      callback();
    }
};
</script>
