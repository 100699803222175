<template>
  <nav class="site-navbar" :class="'site-navbar--' + navbarLayoutType">
    <div class="site-navbar__header">
      <h1 class="site-navbar__brand" @click="$router.push({ name: 'home' })">
        <a class="site-navbar__brand-lg" href="#">北斗设备管理系统</a>
        <a class="site-navbar__brand-mini" href="#">北斗</a>
      </h1>
    </div>
    <div class="site-navbar__body clearfix topbar-right">
      <div class="main-navbar-flod" @click="sidebarFold = !sidebarFold">
        <i class="el-icon-s-unfold" v-if="sidebarFold"></i>
        <i class="el-icon-s-fold" v-else></i>
      </div>
      <div class="main-navbar-avatar">
        <el-dropdown :show-timeout="0" placement="bottom">
          <span class="el-dropdown-link">
            <el-avatar icon="el-icon-user-solid" :style="avatarStyle"></el-avatar>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="updatePasswordHandle()"
              >修改密码</el-dropdown-item
            >
            <el-dropdown-item @click.native="logoutHandle()"
              >退出</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
        <div class="main-navbar-userName">{{ userName }}</div>
      </div>
    </div>
    <!-- 弹窗, 修改密码 -->
    <update-password
      v-if="updatePassowrdVisible"
      :isClose="isClose"
      ref="updatePassowrd"
    ></update-password>
  </nav>
</template>

<script>
import UpdatePassword from "./main-navbar-update-password";
import { clearLoginInfo } from "@/utils";
export default {
  props: {
    isClose: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      updatePassowrdVisible: false,
    };
  },
  components: {
    UpdatePassword,
  },
  computed: {
    navbarLayoutType: {
      get() {
        return this.$store.state.common.navbarLayoutType;
      },
    },
    sidebarFold: {
      get() {
        return this.$store.state.common.sidebarFold;
      },
      set(val) {
        this.$store.commit("common/updateSidebarFold", val);
      },
    },
    mainTabs: {
      get() {
        return this.$store.state.common.mainTabs;
      },
      set(val) {
        this.$store.commit("common/updateMainTabs", val);
      },
    },
    userName: {
      get() {
        return this.$store.state.user.name;
      },
    },
    avatarStyle() {
      let arr = ["#17B3A3", "#EB6709", "#FCB900", "#0BB2D4", "#3E8EF7"],
        random = Math.floor(Math.random() * arr.length),
        bgcolor = arr[random];
      return { "background-color": bgcolor, color: "#fff" };
    }
  },
  mounted() {
    
  },
  methods: {
    // 修改密码
    updatePasswordHandle() {
      this.updatePassowrdVisible = true;
      this.$nextTick(() => {
        this.$refs.updatePassowrd.init();
      });
    },
    // 退出
    logoutHandle() {
      this.$confirm(`确定进行[退出]操作?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http({
            url: this.$http.adornUrl("/sys/logout"),
            method: "post",
            data: this.$http.adornData(),
          }).then(({ data }) => {
            clearLoginInfo();
          });
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
  },
};
</script>
